import React from "react"
import {graphql, Link} from "gatsby";
import type { HeadFC } from "gatsby"
import Seo from "../containers/Seo";
import Layout from "../containers/Layout";


const IndexPage = () => {
  return (
    <Layout>
      <main>
        <div className="hero-unit">
          <div className="container">
            <h1 className="mb-3">自撮りワキ動画 モデル募集</h1>
            <p className="mb-3">
              このサイトは女性のワキに特化した自撮り動画の買い取りを行っています。オンラインの査定で予め目安の金額をお伝えすることで、スムーズなやり取りを目指しています。<br />
              また買い取りは現金(銀行振込)とアマギフの２つに対応しており、最初から最後まで匿名でのやりとりも可能です。<br />
              誰とも接触しない、新しいタイプの副業としておすすめします。
            </p>
            <div className="d-grid">
              <Link className={"btn btn-custom btn-lg"} to={"/submit/"}>査定してみる</Link>
            </div>
          </div>
          <div className="zag" />
        </div>

        <div className="container">
          <h2>ご利用の流れについて</h2>
          <ol>
            <li>簡単なプロフィールと写真を撮影し、査定依頼</li>
            <li>買取金額をお伝えします</li>
            <li>納得頂ける場合、査定額とあわせて送られるガイドラインに従って自撮りを撮影しアップロード。</li>
            <li>動画に問題がなければ報酬はその日に振り込まれます。問題がある場合は再提出をお願いする場合があります。</li>
          </ol>

          <h2>査定の方法</h2>
          <p>査定は簡単なプロフィール入力と、参考写真をアップロードするだけ！24時間以内に査定額をお知らせします。</p>
          <div className="d-grid">
            <Link className={"btn btn-custom btn-lg"} to={"/submit/"}>査定申し込みへ</Link>
          </div>

          <h2>どのような動画を撮影すればいい?</h2>
          <p>参考動画のページからご覧ください。(参考動画は当サイトとは一切関係がありませんのでご注意ください。あくまでも参考のためリンクを貼らせていただきます。)</p>
          <div className="d-grid">
            <Link className={"btn btn-custom btn-lg"} to={"/sample/"}>参考動画をみる</Link>
          </div>

          <h2></h2>
        </div>

      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        siteUrl
      }
    }
  }
`

export const Head: HeadFC = ({data}) => (
  <Seo data={data} />
);

